<template>
  <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="show === true">
    <div class="fixed inset-0 bg-black bg-opacity-30 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-scroll no-scrollbar">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="modal-window">
          <div>
            <div class="mt-3 sm:mt-5">
              <div class="flex flex-col">

                <div class="flex flex-row mt-2 justify-between">
                  <h3 class="text-lg font-semibold leading-6 text-gray-900 mr-5" id="modal-title">
                    {{ textH3 }}
                  </h3>
                  <h4 v-if="additionalText">Номер опенколла: {{ additionalText }}</h4>
                  <button title="Закрыть" @click="hideModal" class="place-self-start">
                    <IconsClose />
                  </button>
                </div>

              </div>
              <!-- Тут содержимое модального окна, которое должно размещаться между заголовком и кнопкой сохранить -->
              <slot />
              <!-- ======================================================================== -->

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits([ 'update:show' ])
const { show, textH3, modalName, additionalText } = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  textH3: {
    type: String,
  },
  modalName: {
    name: String
  },
  additionalText: {
    type: String,
    default: "",
  }
})
function hideModal() {
  emit("update:show", false);
}
</script>

<style scoped>
.modal-window {
  @apply
  bg-white
  dark:bg-gray02-90
  relative
  transform
  rounded-sm
  text-left
  shadow-xl
  transition-all
  sm:my-8
  sm:w-full
  sm:max-w-lg
  px-[30px]
  pt-1
  pb-[40px]
}

.button-group {
  @apply
  flex
  flex-col
  space-y-3
  xs:flex-row
  xs:space-x-6
  xs:space-y-0
  mt-8
}

.processing-consent {
  @apply
  relative
  flex
  items-start
  mt-8
  mb-5
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

</style>
